<template>
  <Loading v-if="isInitLoading" />
  <div v-else>
    <div style="padding-bottom: 50px">
      <!-- 0 是热门详情，1 是盲盒详情 -->
      <Header callBack @click="goBack">
        {{ add == 0 ? anyGetGoodmarketInfoByid.name : getopenBlindBoxObj.name }}
      </Header>

      <div class="details_box">
        <img v-if="this.add == 1" :src="getopenBlindBoxObj.showPic" alt="" />
        <img v-else :src="anyGetGoodmarketInfoByid.showPic" alt="" />
        <div class="details_text"></div>
      </div>
      <!-- 阴影 -->
      <div class="shadow"></div>

      <div class="message">
        <div class="message_collection">
          {{
            add == 0 ? anyGetGoodmarketInfoByid.name : getopenBlindBoxObj.name
          }}
        </div>
      </div>
      <div class="shadow"></div>
      <div class="message" v-if="this.add == 0">
        <div class="message_name">
          <div class="name_box">
            <div class="name_message">商品名称</div>
            <div class="name_num">
              {{
                add == 0
                  ? anyGetGoodmarketInfoByid.name
                  : getopenBlindBoxObj.topicName
              }}
            </div>
          </div>
        </div>
        <div class="issue_box">
          <div class="issue">
            <span class="issue_text">发行</span>
            <span class="issue_num">
              {{ anyGetGoodmarketInfoByid.stockNum }}
              份</span
            >
          </div>
        </div>
      </div>
      <div class="shadow" v-if="this.add == 0"></div>
      <div
        style="padding: 20px; font-size: 16px"
        class="anyGetGoodmarketInfoByidDescribe"
        v-else
        v-html="getopenBlindBoxObj.content"
      ></div>

      <div v-show="show" v-for="item in anyGoodsUserhaveList" :key="item.index">
        <div class="possess">
          <img src="../../../static/image/ren_05.jpg" alt="" />
          <div>
            <div class="possess_name">
              {{ item.goodsNumber }}
              <span>拥有者</span>
            </div>
            <div class="possess_img">
              nfk...dsjfhdskfh
              <img src="../../../static/image/fuzhi@2x.png" alt="" />
            </div>
          </div>
        </div>
      </div>

      <div class="craftsmanInfo" v-if="$route.query.add == 0">
        <div class="nickName">
          {{ craftsmanInfo.user.nickName }} <i>创作者/版权方</i>
        </div>
        <div class="address">
          {{ craftsmanInfo.address }}
          <span class="copy">
            <img
              src="../../../static/image/fuzhi@2x.png"
              @click="copy"
              alt=""
            />
          </span>
        </div>
      </div>
      <div>
        <div
          class="anyGetGoodmarketInfoByidDescribe"
          style="padding: 20px; font-size: 16px"
          v-html="anyGetGoodmarketInfoByid.describe"
        ></div>
      </div>
    </div>
    <div class="bottom">
      <div class="bottom_num" v-if="this.add == 1">
        <span class="num_mon">￥</span>
        <span class="num_seven">{{ getopenBlindBoxObj.fee }}</span>
      </div>

      <div class="bottom_num" v-else>
        <span class="num_mon">￥</span>
        <span class="num_seven">{{ anyGetGoodmarketInfoByid.applyPrice }}</span>
      </div>
      <div style="display: flex; align-items: center">
        <div
          class="tips"
          v-if="anyGetGoodmarketInfoByid.tipsList"
          @click="showTips = true"
        >
          <img src="../../assets/images/tips.png" alt="" />
        </div>
        <div
          class="bottomBut"
          :class="anyGetGoodmarketInfoByid.userDrawBuyNum <= 0 ? 'disable' : ''"
          v-if="anyGetGoodmarketInfoByid.is_draw"
          @click="
            anyGetGoodmarketInfoByid.userDrawBuyNum > 0 &&
              purchase(anyGetGoodmarketInfoByid.id)
          "
        >
          {{
            anyGetGoodmarketInfoByid.userDrawBuyNum > 0
              ? "立即购买"
              : "无购买资格"
          }}
        </div>
        <div
          @click="purchase(anyGetGoodmarketInfoByid.id)"
          class="bottomBut"
          :class="
            getopenBlindBoxObj.dealNum >= getopenBlindBoxObj.stockNum ||
            anyGetGoodmarketInfoByid.isHot == 1
              ? 'disable'
              : ''
          "
          v-else-if="
            anyGetGoodmarketInfoByid.buyTime == 0 ||
            getopenBlindBoxObj.buyTime == 0
          "
        >
          {{
            getopenBlindBoxObj.dealNum >= getopenBlindBoxObj.stockNum
              ? "已售罄"
              : anyGetGoodmarketInfoByid.dealNum >=
                anyGetGoodmarketInfoByid.stockNum
              ? "去市场了解"
              : anyGetGoodmarketInfoByid.isHot == 1
              ? "活动商品，无法购买"
              : "立即购买"
          }}
          <div v-if="anyGetGoodmarketInfoByid.whiteCount">
            剩余优先购次数*{{ anyGetGoodmarketInfoByid.whiteCount }}
          </div>
        </div>
        <!-- <div
          @click="purchase(getopenBlindBoxObj.id)"
          class="bottomBut"
          v-if="
            getopenBlindBoxObj.buyTime == 0
          "
        >
          立即购买
  </div> -->
        <div class="timeBtn" v-else>
          <div>即将开售</div>
          <div v-if="anyGetGoodmarketInfoByid.buyTime">
            剩余
            <span>{{
              anyGetGoodmarketInfoByid.buyTime | limitTimeFilter
            }}</span>
          </div>
          <div v-if="getopenBlindBoxObj.buyTime">
            剩余 <span>{{ getopenBlindBoxObj.buyTime | limitTimeFilter }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom" v-if="false">
      <div class="goGame" @click="$router.push('/tianliangle')">进入游戏</div>
    </div>

    <div class="tipsBpx" v-if="showTips">
      <i class="close" @click="showTips = false"></i>
      <div v-for="item in tipsList" :key="item">{{ item }}</div>
    </div>

    <!-- 开启盲盒-----遮罩层 -->
    <van-overlay :show="mystery">
      <div class="mystery_nei">
        <div class="mystery_block">
          <div class="mystery_text">您有相同的订单还未支付, 快去支付吧!</div>
          <div class="mystery_but">
            <div class="mystery_one" @click="mystery = false">取消</div>
            <div
              class="mystery_tow"
              @click="$router.replace(`/unpaid?id=${goodsId}&orderType=2`)"
            >
              去支付
            </div>
          </div>
        </div>
      </div>
    </van-overlay>

    <div
      v-if="isToHome && !$route.query.platform"
      class="openInApp"
      @click="openApp"
    >
      <div class="img">
        <img src="../../../static/image/logo1.png" alt="" />
      </div>
      <span>APP内打开</span>
    </div>
    <div class="isWxBox" v-if="isWeiXin">
      <div class="xuxian">
        <img src="../../assets/images/xuxian.png" alt="" />
      </div>
      <ul>
        <li>1. 点击右上角的...</li>
        <li>2. 点击进入到浏览器打开</li>
      </ul>
    </div>

    <!-- 实名认证  -->
    <van-overlay :show="autonym1">
      <div class="wrapper">
        <div class="block">
          <div>你还未实名认证</div>
          <div>请先实名认证</div>
          <div class="over_box">
            <div class="over_none" @click="autonym1 = false">取消</div>
            <div class="over_en" @click="confirm_tow">确认</div>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { formateTimeStamp } from "../../utils/tools";
import { isWeiXin } from "../../utils/tools";
export default {
  data() {
    return {
      show: false,
      showTips: false,
      id: "", //传来的id
      anyGetGoodmarketInfoByid: {}, //商场商品详情列表
      anyGoodsUserhaveList: [], //商品拥有者列表
      getopenBlindBoxObj: {},
      buy: 0,
      deal: "",
      tupian: "",
      nona: "",
      zzz: "",
      add: "",
      ticker: null,
      craftsmanInfo: { user: {} },
      tipsList: [],
      isInitLoading: true,
      mystery: false,
      isToHome: false,
      goodsId: undefined,
      isWeiXin: isWeiXin(),
      autonym: false,
      autonym1: false,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.path === "/") {
        vm.isToHome = true;
      } else {
        vm.isToHome = false;
      }
    });
  },
  filters: {
    limitTimeFilter(val) {
      if (val) {
        let formateLimitTimes = formateTimeStamp(val);
        let txt = `${formateLimitTimes.day} 天 ${formateLimitTimes.hour} 时 ${formateLimitTimes.min} 分	${formateLimitTimes.seconds} 秒`;
        txt = `${Number(formateLimitTimes.day)} 天 ${
          formateLimitTimes.hour
        } 时 ${formateLimitTimes.min} 分   ${formateLimitTimes.seconds} 秒`;
        return txt;
      } else {
        const twtxt = `0 天 0 时 0 分 0 秒`;
        return twtxt;
      }
    },
  },
  methods: {
    confirm_tow() {
      this.$router.push("/Autonym");
    },
    goBack() {
      if (this.isToHome) {
        this.$router.replace("/home");
      } else {
        this.$router.back();
      }
    },
    openApp() {
      window.open("https://v.hxnft.shop/appDownload/index.html");
    },
    // 倒计时
    beginTimer() {
      this.ticker = setInterval(() => {
        if (this.anyGetGoodmarketInfoByid.buyTime) {
          this.anyGetGoodmarketInfoByid.buyTime =
            this.anyGetGoodmarketInfoByid.buyTime - 1;
        } else if (this.getopenBlindBoxObj.buyTime) {
          this.getopenBlindBoxObj.buyTime--;
        }
      }, 1000);
    },
    purchase() {
      let token = localStorage.getItem("token");
      if (token == null) {
        if (this.$route.query.code) {
          return this.$router.push(
            `/login?code=${this.$route.query.code}&redirect=/particulars?id=${this.$route.query.id}&add=1`
          );
        }
        return this.$router.push("/login");
      }

      if (this.anyGetGoodmarketInfoByid.isHot == 1) {
        return;
      }

      if (
        this.anyGetGoodmarketInfoByid.dealNum >=
        this.anyGetGoodmarketInfoByid.stockNum
      ) {
        this.$router.push("/category");
        return;
      }

      if (this.$route.query.add == 0) {
        if (this.anyGetGoodmarketInfoByid.blindGoodsId > 0) {
          return this.$toast("盲盒系列藏品，无法直接购买！");
        }
        if (this.anyGetGoodmarketInfoByid.buyTime) {
          return this.$toast("此商品不可购买，请选择其他商品");
        }

        // this.$router.push({
        //   path: '/Order',
        //   query: { id: this.id, img: this.anyGetGoodmarketInfoByid.listPic },
        // });
      } else {
        if (
          this.getopenBlindBoxObj.dealNum >= this.getopenBlindBoxObj.stockNum
        ) {
          return;
        }
        if (this.getopenBlindBoxObj.buyTime) {
          return this.$toast("此商品不可购买，请选择其他商品");
        }

        // this.$router.push({
        //   path: '/Order',
        //   query: { id: this.id, img: this.getopenBlindBoxObj.listPic },
        // });
      }
      if (this.autonym) {
        this.autonym1 = true;
        return;
      }
      // 获取详情信息(锁单)
      let reg = {
        id: this.id,
        token: localStorage.getItem("token"),
      };
      this.$api.detail(reg).then((e) => {
        if (e.code === 100000) {
          this.$router.push({
            path: "/Order",
            query: {
              id: this.id,
              img: this.getopenBlindBoxObj.listPic,
              item: JSON.stringify(e.data[0]),
            },
          });
        } else if (e.code == 10002) {
          this.mystery = true;
          this.goodsId = e.id;
          // this.$router.replace(`/unpaid?id=${e.id}&orderType=2`);
        } else {
          this.$toast(e.msg);
        }
      });
    },

    // 下拉显示隐藏
    botom() {
      if (this.show == false) {
        this.show = true;
      } else if (this.show == true) {
        this.show = false;
      }
    },
    copy() {
      //新建一个文本框
      let oInput = document.createElement("input");
      //赋值给文本框
      oInput.value = this.craftsmanInfo.address;
      document.body.appendChild(oInput);
      // 选择对象;
      oInput.select();
      // 执行浏览器复制命令
      document.execCommand("Copy");
      //复制完成删除掉输入框
      oInput.remove();
      this.$toast("复制成功");
    },
    // 获取商品拥有者
    getAnyGoodsUserhaveList(blindGoodsId) {
      const params = {
        id: blindGoodsId,
        type: 2,
        token: localStorage.getItem("token"),
      };
      this.$api.anyGoodsUserhaveList(params).then((res) => {
        if (res.code == 0) {
          this.craftsmanInfo = res.craftsmanInfo || { user: {} };
        }
      });
    },
  },

  mounted() {
    this.$api.Userinfo({ token: localStorage.getItem("token") }).then((res) => {
      if (res.data.idcardStatus != 1) {
        this.autonym = true;
      } else {
        this.autonym = false;
      }
    });
    this.isInitLoading = true;
    if (this.ticker) {
      clearInterval(this.ticker);
    }
    this.beginTimer();
    let query = this.$route.query;
    this.id = query.id;
    this.add = query.add;
    this.tipsList = [];
    // 商品详情
    if (query.add == 0) {
      this.$api
        .anyGoodsdetail({ id: query.id, token: localStorage.getItem("token") })
        .then(
          (res) => {
            console.log(res);
            this.isInitLoading = false;
            if (res.code == 0) {
              this.anyGetGoodmarketInfoByid = res.data;
              this.tipsList = (
                this.anyGetGoodmarketInfoByid.tipsList || ""
              ).split("\n");
              this.getopenBlindBoxObj = {};
              this.deal = res.data.isDeal;
              // // 详情图片
              this.tupian = res.data.describe;
              // 商品拥有者
              if (this.$route.query.add == 0) {
                this.getAnyGoodsUserhaveList(res.data.id);
                // this.getAnyGoodsUserhaveList(res.data.blindGoodsId);
              }
              let a = this.tupian;
              var b = /<img[^>]+src=['"]([^'"]+)['"]+/g;
              let result = [];
              let temp = [];
              while ((temp = b.exec(a)) != null) {
                result.push({ url: temp[1] });
              }
              this.temp = result;
            }
          },
          () => {
            this.isInitLoading = false;
          }
        );
    } else {
      this.$api
        .blindBoxDetail({ id: query.id, token: localStorage.getItem("token") })
        .then(
          (res) => {
            this.isInitLoading = false;
            if (res.code == 0) {
              this.getopenBlindBoxObj = res.info;
              this.anyGetGoodmarketInfoByid = {};
              // this.deal = res.data.isDeal;
              // // 详情图片
              this.tupian = res.info.listPic;
              let a = this.tupian;
              var b = /<img[^>]+src=['"]([^'"]+)['"]+/g;
              let result = [];
              let temp = [];
              while ((temp = b.exec(a)) != null) {
                result.push({ url: temp[1] });
              }
              this.temp = result;
            }
          },
          () => {
            this.isInitLoading = false;
          }
        );
    }
  },
};
</script>
<style lang="less" scoped>
.tips {
  margin-right: 15px;
  img {
    width: 15px;
  }
}
.cao {
  width: 100%;
}

.details_box {
  width: 100vw;
  // height: 300px;
  img {
    width: 100%;
    height: 100%;
    display: inline-block;
    // object-fit: cover;
  }

  .details_text {
    font-size: 18px;
    font-weight: 700;
    line-height: 62px;
    margin-left: 10px;
  }
}

// 阴影
.shadow {
  background-color: #f1f1f1;
  height: 10px;
}

.message {
  margin: 0 16px;
  padding: 15px 0;
}

.message_collection {
  font-size: 18px;
  font-weight: 700;
}

.message_name {
  display: flex;
  justify-content: space-between;

  .name_box {
    display: flex;
    align-items: center;

    .name_message {
      font-size: 14px;
      // line-height: 50px;
    }

    .name_num {
      font-size: 14px;
      color: black;
      font-weight: 600;
      margin-left: 10px;
    }

    .name_all {
      font-size: 14px;
    }

    img {
      width: 6px;
      height: 10px;
      display: inline-block;
      margin-right: 16px;
      margin-left: 7px;
    }
  }
}

.issue_box {
  display: flex;

  .issue {
    .issue_text {
      background-color: #abaab9;
      font-size: 12px;
      color: #ffffff;
      display: inline-block;
      height: 20px;
      width: 34px;
      text-align: center;
      line-height: 20px;
    }

    // padding-bottom: 25px;

    .issue_num {
      font-size: 12px;
      line-height: 20px;
      height: 20px;
      display: inline-block;
      background-color: #f3f4f8;
      padding-left: 5px;
      padding-right: 5px;
      color: #4c4b58;
      margin-right: 10px;
    }
  }
}

.mark_text {
  font-size: 16px;
  color: #999999;
}

.mark_num {
  margin-left: 16px;
  font-size: 14px;
  color: #000000;

  .mark_img {
    width: 13px;
    height: 13px;
    display: inline-block;
    // background-color: black;
  }
}

.possess {
  padding-top: 20px;
  padding-left: 16px;
  display: flex;
  align-items: center;
  display: flex;
  margin-bottom: 15px;

  .botom {
    width: 10px;
    position: absolute;
    right: 10px;
    height: 6px;
  }

  img {
    display: block;
    width: 36px;
    height: 36px;
    padding-right: 15px;
  }

  .possess_name {
    font-size: 12px;
    font-weight: 600;

    .yongyou {
      color: #ffaf09;
    }

    span {
      color: blue;
      font-size: 10px;
    }
  }

  .possess_img {
    display: flex;
    align-items: center;
    font-size: 14px;

    img {
      display: block;
      width: 10px;
      height: 10px;
      margin-left: 10px;
    }
  }
}

.author {
  text-align: center;
  width: 342px;
  padding-bottom: 20px;
  background-color: #ffffff;
  border-radius: 15px;
  margin-left: 18px;
  margin-bottom: 18px;

  .author_intro {
    font-size: 18px;
    font-weight: 600;
    line-height: 64px;
  }

  img {
    width: 137px;
    height: 172px;
  }

  .author_img {
    font-size: 14px;
    font-weight: 600;
    line-height: 40px;
  }

  .author_text {
    margin-left: 44px;
    margin-right: 44px;
    font-size: 16px;
  }
}

.abridged {
  width: 100%;
  background-color: bisque;
  // padding-top: 20px;
  padding-bottom: 10px;
}
.tipsBpx {
  position: fixed;
  bottom: 50px;
  // height: 200px;
  z-index: 999;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  padding: 20px;
  box-sizing: border-box;
  .close {
    position: absolute;
    width: 15px;
    height: 15px;
    right: 10px;
    top: 10px;
    &::after {
      visibility: visible;
      display: block;
      content: "";
      width: 100%;
      height: 2px;
      background: #fff;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
    }
    &::before {
      visibility: visible;
      display: block;
      content: "";
      width: 100%;
      height: 2px;
      background: #fff;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
  div {
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 28px;
  }
}

.bottom {
  position: fixed;
  z-index: 999;
  bottom: 0;
  width: 100%;
  height: 50px;
  display: flex;
  background-color: #ffffff;
  align-items: center;
  justify-content: space-between;
  // padding-left: 16px;
  // padding-right: 16px;

  .num_mon {
    font-size: 10px;
  }

  .num_seven {
    font-size: 20px;
  }

  .bottomBut {
    width: 200px;
    height: 40px;
    background-color: #0754d3;
    text-align: center;
    // line-height: 40px;
    font-size: 15px;
    border-radius: 10px;
    color: #ffffff;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    div {
      font-size: 11px;
    }
    &.disable {
      background-color: #aaa;
    }
  }
  .goGame {
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background: #0754d3;
    margin: 0 auto;
    border-radius: 5px;
    height: 80%;
    font-size: 16px;
  }
}

.hig {
  height: 40px;
  background-color: #ffaf09;
}

.descri {
  min-width: 100%;

  object-fit: cover;
  width: 100%;
  max-width: 100%;

  img {
    width: 100%;
  }
}

.bottom_num {
  padding-left: 16px;
}
.timeBtn {
  // width: 125px;
  padding-right: 10px;
  padding-left: 10px;
  height: 40px;
  background: #ececec;
  border-radius: 5px;
  margin-right: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 400;
  color: #999999;
}

.timeBtn div:first-child {
  font-size: 14px;
}

.timeBtn div:last-child {
  font-size: 10px;
}
.craftsmanInfo {
  padding: 20px;
  .nickName {
    font-size: 12px;
    font-weight: 600;
    i {
      font-style: italic;
      color: #0754d3;
      margin-left: 5px;
    }
  }
  .address {
    font-size: 12px;
    color: #acabb1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .copy {
      width: 13px;
      display: block;
      img {
        width: 100%;
      }
    }
  }
}
.mystery_nei {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  .mystery_block {
    background-color: #fff;
    width: 250px;
    height: 150px;
    border-radius: 10px;
    .mystery_text {
      font-size: 16px;
      text-align: center;
      margin: 20px auto 0;
      width: 80%;
    }
    .mystery_but {
      display: flex;
      font-size: 14px;
      justify-content: space-around;
      margin-top: 20px;
      .mystery_tow {
        height: 30px;
        line-height: 30px;
        width: 80px;
        text-align: center;
        background-color: black;
        color: #ffffff;
      }
      .mystery_one {
        height: 30px;
        line-height: 30px;
        width: 80px;
        text-align: center;
        // background-color: ;
        border: 1px black solid;
      }
    }
  }
}
/deep/.van-overlay {
  z-index: 999;
}
.openInApp {
  position: fixed;
  right: 0;
  bottom: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #fff;
  border: 1px solid #ddd;
  padding: 5px;
  border-radius: 3px;
  .img {
    width: 40px;
    height: 40px;
    margin-bottom: 10px;
    img {
      width: 100%;
      height: 100%;
      vertical-align: middle;
    }
  }
  span {
    font-size: 12px;
  }
}
.isWxBox {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 999;
  .xuxian {
    text-align: right;
    padding-right: 10px;
  }
  .xuxian img {
    width: 111px;
  }
  ul {
    padding-right: 10px;
    margin-top: 10px;
    li {
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #ffffff;
      text-align: left;
      text-align: right;
      margin-right: 20px;
    }
  }
}

.block {
  font-size: 16px;
  text-align: center;
  border-radius: 5px;
  padding-top: 30px;
  width: 300px;
  margin: 0 auto;
  margin-top: 60%;
  background-color: #fff;
}

.over_box {
  margin-top: 20px;
  padding-bottom: 30px;
  display: flex;
  justify-content: space-around;
  font-size: 13px;

  .over_none {
    color: #0754d3;
    width: 74px;
    text-align: center;
    line-height: 25px;
    height: 25px;
    border-radius: 5px;
    border: 1px #cecece solid;
  }

  .over_en {
    color: #ffffff;
    width: 74px;
    text-align: center;
    background-color: #0754d3;
    line-height: 25px;
    height: 25px;
    border-radius: 5px;
    border: 1px #0754d3 solid;
  }
}
</style>
<style>
.anyGetGoodmarketInfoByidDescribe img {
  max-width: 100%;
}
</style>
